<template>
  <div class="wrapper">
    <!-- Notifications Container -->
    <notifications></notifications>
    <!-- Sidebar -->
    <dashboard-sidebar :plans="plans" />
    <!-- Main Content -->
    <div class="main-content">
      <!-- Navbar / Only Visible Smaller Screens-->
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <!-- Content -->
      <div @click="$sidebar.displaySidebar(false)" style="min-height: 90vh">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view :plans="plans"></router-view>
        </fade-transition>
      </div>

      <!-- Footer -->
      <dashboard-footer v-if="!$route.meta.hideFooter"></dashboard-footer>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import DashboardFooter from "./DashboardFooter.vue";
import DashboardSidebar from "./DashboardSidebar.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    DashboardFooter,
    DashboardSidebar,
    FadeTransition,
  },
  data: () => ({
    plans: null,
  }),
  methods: {
    async getPlans() {
      const response = await this.$store.dispatch("plan/getPlans");
      this.plans = response.data.plans;
    },
    async getHasPlan() {
      const response = await this.$store.dispatch("user/hasPlan");
      return response.data.hasPlan;
    },
    async getCurrentPlan() {
      await this.$store.dispatch("currentPlan/getCurrentPlan");
    },
  },
  async mounted() {
    await this.getPlans();
    const hasPlan = await this.getHasPlan();
    if (hasPlan) await this.getCurrentPlan();
  },
};
</script>
<style></style>
