<template>
  <router-view></router-view>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$i18n.t("website.title"),
      titleTemplate: `%s | ${this.$t("website.domain")}`,
    };
  },
};
</script>
