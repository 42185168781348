import AuthLayout from "@/views/Layouts/AuthLayout.vue";
import Store from "@/store/store.js";
import i18n from "@/i18n";

export default [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: i18n.t("routerPaths.auth.signIn"),
        name: "signIn",
        meta: {
          public: true,
          onlyWhenLoggedOut: true,
        },
        component: () =>
          import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/SignIn"),
      },
      {
        path: i18n.t("routerPaths.auth.signUp"),
        name: "signUp",
        meta: {
          public: true,
          onlyWhenLoggedOut: true,
        },
        component: () =>
          import(/* webpackChunkName: "pages" */ "@/views/Pages/Auth/SignUp"),
      },
      {
        path: i18n.t("routerPaths.auth.forgotPassword"),
        name: "forgotPassword",
        meta: {
          public: true,
          onlyWhenLoggedOut: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "pages" */ "@/views/Pages/Auth/ForgotPassword"
          ),
      },
      {
        path: i18n.t("routerPaths.auth.resetPassword"),
        name: "resetPassword",
        meta: {
          public: true,
          onlyWhenLoggedOut: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "pages" */ "@/views/Pages/Auth/ResetPassword"
          ),
        async beforeEnter(to, from, next) {
          if (to.query.token) {
            try {
              atob(to.query.token).split(":");
            } catch (error) {
              next({ name: "signIn" });
            }
            next();
          } else next({ name: "signIn" });
        },
      },
      {
        path: i18n.t("routerPaths.auth.resendVerificationEmail"),
        name: "resendVerificationEmail",
        meta: {
          public: true,
          onlyWhenLoggedOut: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "pages" */ "@/views/Pages/Auth/ResendVerificationEmail"
          ),
      },
      {
        path: i18n.t("routerPaths.auth.verifyEmail"),
        name: "verifyEmail",
        meta: {
          public: true,
          onlyWhenLoggedOut: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "pages" */ "@/views/Pages/Auth/VerifyEmail"
          ),
        async beforeEnter(to, from, next) {
          if (to.query.token) {
            try {
              const queryToken = atob(to.query.token).split(":");
              const response = await Store.dispatch("auth/verifyEmailAddress", {
                emailAddress: queryToken[0],
                token: queryToken[1],
              });
              if (!response) next({ name: "signIn" });
              else next();
            } catch (error) {
              next({ name: "signIn" });
            }
          } else next({ name: "signIn" });
        },
      },
      {
        props: true,
        path: i18n.t("routerPaths.pricing"),
        name: "pricing",
        meta: {
          public: true,
          onlyWhenLoggedOut: false,
        },
        component: () =>
          import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing"),
        async beforeEnter(to, from, next) {
          const response = await Store.dispatch("plan/getPlans");
          to.params.plans = response.data.plans;
          next();
        },
      },
    ],
  },
];
