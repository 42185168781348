import axios from "axios";
import StorageService from "./storage.service";
import store from "@/store/store";
import router from "@/router/router";

class ApiError extends Error {
  constructor(errorCode, errorMessage) {
    super(errorMessage);
    this.name = this.constructor.name;
    this.errorMessage = errorMessage;
    this.errorCode = errorCode;
  }
}

const ApiService = {
  _401Interceptor: null,
  baseAddress: process.env.VUE_APP_API_ADDRESS,
  baseSocketAddress: process.env.VUE_APP_API_ADDRESS,

  init() {},

  setLanguageHeader(language) {
    axios.defaults.headers.common["Accept-Language"] = language;
  },

  setAuthorizationHeader() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${StorageService.getToken()}`;
  },

  removeAuthorizationHeader() {
    axios.defaults.headers.common["Authorization"] = "";
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource, data) {
    return axios.delete(resource, { data: data });
  },

  customRequest(data) {
    return axios(data);
  },

  mount401Interceptor() {
    this._401Interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.request.status == 401) {
          store.dispatch("auth/signOut", false);
          router.push({
            name: "signIn",
            query: { redirect: router.currentRoute.path },
          });
          throw error;
        }
        throw error;
      }
    );
  },

  unmount401Interceptor() {
    axios.interceptors.response.eject(this._401Interceptor);
  },
};

export { ApiService, ApiError };
