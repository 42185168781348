import Vue from "vue";
import Vuex from "vuex";

import * as auth from "./modules/auth";
import * as user from "./modules/user";
import * as plan from "./modules/plan";
import * as currentPlan from "./modules/currentPlan";
import * as currentPlanUser from "./modules/currentPlanUser";
import * as subscription from "./modules/subscription";
import * as notification from "./modules/notification";
import createWebSocketPlugin from "./plugins/global";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    plan,
    currentPlan,
    currentPlanUser,
    subscription,
    notification,
  },
  plugins: [createWebSocketPlugin()],
});
