export const namespaced = true;

export const state = {
  notifications: [],
};

export const actions = {
  addNotification({ commit }, notification) {
    commit("addNotification", notification);
  },
};

export const mutations = {
  addNotification(state, notification) {
    state.notifications.push(notification);
    let icon = null;
    if (notification.type == "success") icon = "fas fa-check-circle";
    else if (notification.type == "danger") icon = "fas fa-exclamation-circle";
    else if (notification.type == "info") icon = "fas fa-info-circle";
    this._vm.$notify({
      message: notification.message,
      timeout: notification.timeout,
      icon: icon,
      type: notification.type,
      verticalAlign: "bottom",
      horizontalAlign: "right",
    });
  },
};
