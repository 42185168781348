import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/store";
import i18n from "./i18n";
import DashboardPlugin from "./plugins/dashboard-plugin";
import StorageService from "./services/storage.service";
import { ApiService } from "./services/api.service";

Vue.use(DashboardPlugin);

Vue.config.productionTip = false;

ApiService.setLanguageHeader(process.env.VUE_APP_I18N_LOCALE);

// If token and user exist set header & mount interceptor
if (StorageService.getToken()) {
  ApiService.setAuthorizationHeader();
  ApiService.mount401Interceptor();
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
