import { PlanService } from "@/services/plan.service";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async getPlans({ commit, dispatch }) {
    try {
      const response = await PlanService.getPlans();
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },
};
