<template>
  <base-nav
    container-classes="container-fluid"
    class="d-xl-none navbar-top border-bottom navbar-expand"
    :class="{ 'bg-primary navbar-dark': type === 'default' }"
  >
    <template slot="brand">
      <b-img
        src="https://demos.creative-tim.com/vue-argon-dashboard-pro/img/brand/green.png"
        style="max-height: 2rem"
      ></b-img>
    </template>
    <b-navbar-nav class="align-items-center ml-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler"
          :class="{
            active: $sidebar.showSidebar,
            'sidenav-toggler-dark': type === 'default',
            'sidenav-toggler-light': type === 'light',
          }"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </b-navbar-nav>
  </base-nav>
</template>
<script>
import { BaseNav } from "@/components";

export default {
  components: {
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description: "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  data() {
    return {};
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
  },
};
</script>
