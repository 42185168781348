import { UserService } from "../../services/user.service";

export const namespaced = true;

export const state = {
  hasPlan: null,
};

export const getters = {};

export const mutations = {
  setHasPlan(state, hasPlan) {
    state.hasPlan = hasPlan;
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async getUser({ dispatch }) {
    try {
      const response = await UserService.getUser();
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async hasPlan({ commit, dispatch }) {
    try {
      const response = await UserService.hasPlan();
      commit("setHasPlan", response.data.hasPlan);
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async updateUser({ commit, dispatch }, user) {
    try {
      const response = await UserService.updateUser(user);
      commit("auth/userUpdated", response.data, { root: true });
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async changePassword({ dispatch }, password) {
    try {
      const response = await UserService.changePassword(password);
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  setUserHasPlan({ commit }, hasPlan) {
    commit("setHasPlan", hasPlan);
  },
};
