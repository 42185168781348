<template>
  <div>
    <notifications></notifications>

    <b-navbar
      class="navbar-transparent navbar-horizontal navbar-main"
      type="light"
      menu-classes="justify-content-end"
    >
      <div class="container">
        <b-navbar-brand to="/">
          <img src="/img/brand/white.png" />
        </b-navbar-brand>
        <b-navbar-nav class="align-items-lg-center ml-lg-auto">
          <base-button
            v-if="signedIn"
            icon
            type="neutral"
            @click="$router.push({ name: 'dashboard' })"
          >
            <span class="btn-inner--icon">
              <i class="fas fa-grip-horizontal"></i>
            </span>
            <span class="btn-inner--text">Dashboard</span>
          </base-button>
          <base-button
            v-else
            icon
            type="neutral"
            @click="$router.push({ name: 'signIn' })"
          >
            <span class="btn-inner--icon">
              <i class="fas fa-sign-in-alt"></i>
            </span>
            <span class="btn-inner--text">{{ $t("navbar.signIn") }}</span>
          </base-button>
        </b-navbar-nav>
      </div>
    </b-navbar>

    <div class="main-content mb-2 mb-xl-4" style="min-height: 92vh">
      <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
      </zoom-center-transition>
      <router-view></router-view>
    </div>

    <footer class="pb-4" id="footer-main">
      <b-container>
        <b-row align-v="center" class="justify-content-xl-between">
          <b-col xl="6">
            <div class="copyright text-center text-xl-left text-muted">
              Thousand Bytes® {{ year }}
            </div>
          </b-col>
          <b-col xl="6" class="col-xl-6">
            <b-nav
              class="nav-footer justify-content-center justify-content-xl-end"
            >
              <b-nav-item href="https://www.thousandbytes.com" target="_blank">
                Thousand Bytes
              </b-nav-item>
              <b-nav-item
                :to="{ path: $t('routerPaths.support.aboutUs') }"
                target="_blank"
              >
                {{ $t("routerTitles.support.aboutUs") }}
              </b-nav-item>
              <b-nav-item
                :to="{ path: $t('routerPaths.support.contact') }"
                target="_blank"
              >
                {{ $t("routerTitles.support.contact") }}
              </b-nav-item>
              <b-nav-item
                :to="{ path: $t('routerPaths.support.faq') }"
                target="_blank"
              >
                {{ $t("routerTitles.support.faq") }}
              </b-nav-item>
            </b-nav>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>
<script>
import { ZoomCenterTransition } from "vue2-transitions";
import { mapGetters } from "vuex";

export default {
  components: {
    ZoomCenterTransition,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  computed: {
    ...mapGetters("auth", ["signedIn"]),
  },
  data() {
    return {
      showMenu: false,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
    };
  },
  methods: {
    setBackgroundColor() {
      document.body.classList.add("bg-default");
    },
    removeBackgroundColor() {
      document.body.classList.remove("bg-default");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
  },
  beforeDestroy() {
    this.removeBackgroundColor();
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>
