import { AuthService } from "@/services/auth.service";
import StorageService from "@/services/storage.service";
import router from "@/router/router";
import i18n from "@/i18n";

export const namespaced = true;

export const state = {
  token: StorageService.getToken(),
  user: StorageService.getUser(),
};

export const getters = {
  signedIn: (state) => {
    return state.token && state.user ? true : false;
  },
};

export const mutations = {
  /*setUser(state, user) {
    state.user = user;
    StorageService.saveUser(user);
  },*/

  userUpdated(state, updatedUser) {
    StorageService.updateUser(updatedUser);
    state.user.firstName = updatedUser.firstName;
    state.user.lastName = updatedUser.lastName;
  },

  signInSuccess(state, response) {
    state.token = response.token;
    state.user = response.user;
    router.push(router.history.current.query.redirect || "/");
  },

  signOutSuccess(state) {
    state.token = "";
    state.user = "";
  },
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async signUp({ commit, dispatch }, credentials) {
    try {
      const response = await AuthService.signUp(credentials);
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async signIn({ commit, dispatch }, credentials) {
    try {
      const response = await AuthService.signIn(credentials);
      commit("signInSuccess", response.data);
      return response;
    } catch (error) {
      if (error.errorCode == 409) {
        dispatch(
          "notification/addNotification",
          {
            type: "danger",
            message: `${error.errorMessage}<br/>${i18n.t("auth.verificationError")}`,
            timeout: null,
          },
          { root: true }
        );
      } else {
        dispatch(
          "notification/addNotification",
          {
            type: "danger",
            message: error.errorMessage,
            timeout: 3000,
          },
          { root: true }
        );
      }
    }
  },

  async verifyEmailAddress({ dispatch }, credentials) {
    try {
      const response = await AuthService.verifyEmailAddress(credentials);
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async resendVerificationEmail({ dispatch }, credentials) {
    try {
      const response = await AuthService.resendVerificationEmail(credentials);
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async sendResetPasswordEmail({ dispatch }, credentials) {
    try {
      const response = await AuthService.sendResetPasswordEmail(credentials);
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        {
          root: true,
        }
      );
    }
  },

  async resetPassword({ dispatch }, credentials) {
    try {
      const response = await AuthService.resetPassword(credentials);
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  setUser({ commit }, user) {
    commit("setUser", user);
  },

  signOut({ commit }) {
    AuthService.signOut();
    commit("signOutSuccess");
    router.push({ name: "signIn" });
  },
};
