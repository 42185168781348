var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-bar',[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
        name: 'Dashboard',
        icon: 'ni ni-app text-primary',
        path: '/dashboard',
      }}}),_c('sidebar-item',{attrs:{"link":{
        name: 'Subscription',
        icon: 'ni ni-credit-card text-success',
        path: ("/" + (_vm.$t('routerPaths.subscription'))),
      }}})],1),_c('template',{slot:"links-after"},[_c('hr',{staticClass:"my-3",staticStyle:{"width":"100%"}}),_c('dashboard-user-menu',{attrs:{"plans":_vm.plans}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }