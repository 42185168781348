const USER = "user";
const TOKEN = "token";
const CURRENT_USER = "currentUser";

const StorageService = {
  getCurrentUser() {
    if (JSON.parse(localStorage.getItem(CURRENT_USER))) return JSON.parse(localStorage.getItem(CURRENT_USER));
    else return null;
  },

  getUser() {
    if (JSON.parse(localStorage.getItem(USER))) return JSON.parse(localStorage.getItem(USER));
    else return null;
  },

  getToken() {
    if (JSON.parse(localStorage.getItem(TOKEN))) return JSON.parse(localStorage.getItem(TOKEN));
    else return null;
  },

  saveCurrentUser(currentUser) {
    localStorage.setItem(CURRENT_USER, JSON.stringify(currentUser));
  },

  saveUser(user) {
    localStorage.setItem(USER, JSON.stringify(user));
  },

  saveToken(token) {
    localStorage.setItem(TOKEN, JSON.stringify(token));
  },

  removeUser() {
    localStorage.removeItem(USER);
  },

  removeToken() {
    localStorage.removeItem(TOKEN);
  },

  updateUser(updatedUser) {
    var user = JSON.parse(localStorage.getItem(USER));
    user.firstName = updatedUser.firstName;
    user.lastName = updatedUser.lastName;
    this.saveUser(user);
  },
};

export default StorageService;
