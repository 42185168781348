import { ApiService, ApiError } from "./api.service";
import StorageService from "./storage.service";
import i18n from "@/i18n";

const AuthService = {
  async signUp(credentials) {
    try {
      const response = await ApiService.post(ApiService.baseAddress + "auth/signup", credentials);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new ApiError(error.response.data.code, error.response.data.message);
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  async signIn(credentials) {
    try {
      const response = await ApiService.post(ApiService.baseAddress + "auth/signin", credentials);
      StorageService.saveUser(response.data.data.user);
      StorageService.saveToken(response.data.data.token);
      ApiService.setAuthorizationHeader();
      ApiService.mount401Interceptor();
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new ApiError(error.response.data.code, error.response.data.message);
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  async verifyEmailAddress(credentials) {
    try {
      const response = await ApiService.post(ApiService.baseAddress + "auth/verifyemail", credentials);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new ApiError(error.response.data.code, error.response.data.message);
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  async resendVerificationEmail(credentials) {
    try {
      const response = await ApiService.post(ApiService.baseAddress + "auth/resendverificationemail", credentials);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new ApiError(error.response.data.code, error.response.data.message);
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  async sendResetPasswordEmail(credentials) {
    try {
      const response = await ApiService.post(ApiService.baseAddress + "auth/sendResetPasswordEmail", credentials);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new ApiError(error.response.data.code, error.response.data.message);
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  async resetPassword(credentials) {
    try {
      const response = await ApiService.post(ApiService.baseAddress + "auth/resetPassword", credentials);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new ApiError(error.response.data.code, error.response.data.message);
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  signOut() {
    StorageService.removeUser();
    StorageService.removeToken();
    ApiService.removeAuthorizationHeader();
    ApiService.unmount401Interceptor();
  },
};

export { AuthService };
