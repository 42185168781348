import { ApiService, ApiError } from "./api.service";
import i18n from "@/i18n";

const UserService = {
  async getUser() {
    try {
      const response = await ApiService.get(ApiService.baseAddress + "user");
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status == 401) throw new ApiError(0, i18n.t("notifications.sessionError"));
        else throw new ApiError(error.response.data.code, error.response.data.message);
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  async hasPlan() {
    try {
      const response = await ApiService.get(ApiService.baseAddress + "user/hasPlan");
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status == 401) throw new ApiError(0, i18n.t("notifications.sessionError"));
        else throw new ApiError(error.response.data.code, error.response.data.message);
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  async updateUser(user) {
    try {
      const response = await ApiService.post(ApiService.baseAddress + "user", user);
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status == 401) throw new ApiError(0, i18n.t("notifications.sessionError"));
        else throw new ApiError(error.response.data.code, error.response.data.message);
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  async changePassword(password) {
    try {
      const response = await ApiService.post(ApiService.baseAddress + "user/changePassword", password);
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status == 401) throw new ApiError(0, i18n.t("notifications.sessionError"));
        else throw new ApiError(error.response.data.code, error.response.data.message);
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },
};

export { UserService };
