import Vue from "vue";
import Router from "vue-router";
import store from "@/store/store";
import Meta from "vue-meta";
import i18n from "@/i18n";

import AuthRoutes from "./routes/auth";
import PaymentRoutes from "./routes/payment";
import DashboardLayout from "@/views/Layouts/DashboardLayout.vue";

Vue.use(Router);
Vue.use(Meta);

const ROUTES = [
  {
    path: "*",
    meta: {
      public: true,
    },
    component: () => import(/* webpackChunkName: "pages" */ "@/views/Pages/Error/NotFound"),
  },
  {
    path: "/",
    redirect: i18n.t("routerPaths.dashboard"),
    meta: {
      public: false,
      onlyWhenLoggedOut: false,
    },
    component: DashboardLayout,
    children: [
      {
        path: i18n.t("routerPaths.dashboard"),
        name: "dashboard",
        component: () => import(/* webpackChunkName: "pages" */ "@/views/Pages/Dashboard/Dashboard"),
      },
      {
        path: i18n.t("routerPaths.subscription"),
        name: "subscription",
        component: () => import(/* webpackChunkName: "pages" */ "@/views/Pages/Dashboard/Subscription"),
      },
      {
        path: i18n.t("routerPaths.myProfile"),
        name: "myProfile",
        component: () => import(/* webpackChunkName: "pages" */ "@/views/Pages/Dashboard/MyProfile"),
      },
    ],
  },
]
  .concat(AuthRoutes)
  .concat(PaymentRoutes);

const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes: ROUTES,
  linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    window.location.reload();
  }
});

router.beforeEach(async (to, from, next) => {
  // Check if user logged
  //const isAdmin = to.matched.some((record) => record.meta.isAdmin);
  const isPublic = to.matched.some((record) => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some((record) => record.meta.onlyWhenLoggedOut);
  let loggedIn = store.getters["auth/signedIn"];

  /*if (isAdmin) {
    if (loggedIn) {
      let user = await store.dispatch("user/getUser");
      if (user.data.emailAddress !== "admin@hesapal.org") {
        return setTimeout(
          () =>
            next({
              path: "/",
            }),
          10
        );
      }
    } else {
      return setTimeout(
        () =>
          next({
            path: "/",
          }),
        10
      );
    }
  }*/

  if (!isPublic && !loggedIn) {
    return setTimeout(
      () =>
        next({
          name: "signIn",
          query: { redirect: to.fullPath },
        }),
      10
    );
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return setTimeout(() => next("/"), 10);
  }

  setTimeout(() => next(), 10);
});

export default router;
