import { ApiService, ApiError } from "./api.service";
import i18n from "@/i18n";

const CurrentPlanService = {
  async getCurrentPlan() {
    try {
      const response = await ApiService.get(
        ApiService.baseAddress + "currentPlan"
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status == 401)
          throw new ApiError(0, i18n.t("notifications.sessionError"));
        else
          throw new ApiError(
            error.response.data.code,
            error.response.data.message
          );
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },
};

export { CurrentPlanService };
