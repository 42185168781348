/* eslint-disable no-unused-vars */
import { CurrentPlanUserService } from "@/services/currentPlanUser.service";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {
  userAdded(state) {},
  userStatusUpdated(state) {},
  userUpdated(state) {},
  userDeleted(state) {},
};

export const actions = {
  async addUser({ commit, dispatch }, user) {
    try {
      const response = await CurrentPlanUserService.addUser(user);
      commit("userAdded");
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async updateUser({ commit, dispatch }, user) {
    try {
      const response = await CurrentPlanUserService.updateUser(user);
      commit("userUpdated");
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async updateStatus({ commit, dispatch }, userId) {
    try {
      const response = await CurrentPlanUserService.updateStatus(userId);
      commit("userStatusUpdated");
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async deleteUser({ commit, dispatch }, userId) {
    try {
      const response = await CurrentPlanUserService.deleteUser(userId);
      commit("userDeleted");
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },
};
