import AuthLayout from "@/views/Layouts/AuthLayout.vue";
import i18n from "@/i18n";

export default [
  {
    path: `/${i18n.t("routerPaths.payment.payment")}`,
    component: AuthLayout,
    children: [
      {
        path: i18n.t("routerPaths.payment.success"),
        name: "paymentSuccess",
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
        },
        component: () => import(/* webpackChunkName: "pages" */ "@/views/Pages/Payment/PaymentSuccess"),
      },
      {
        path: i18n.t("routerPaths.payment.canceled"),
        name: "paymentCanceled",
        meta: {
          public: false,
          onlyWhenLoggedOut: false,
        },
        component: () => import(/* webpackChunkName: "pages" */ "@/views/Pages/Payment/PaymentCanceled"),
      },
    ],
  },
];
