import { ApiService, ApiError } from "./api.service";
import i18n from "@/i18n";

const CurrentPlanUserService = {
  async addUser(user) {
    try {
      const response = await ApiService.post(
        ApiService.baseAddress + "currentPlanUser",
        user
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status == 401)
          throw new ApiError(0, i18n.t("notifications.sessionError"));
        else
          throw new ApiError(
            error.response.data.code,
            error.response.data.message
          );
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  async updateUser(user) {
    try {
      const response = await ApiService.put(
        ApiService.baseAddress + "currentPlanUser",
        user
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status == 401)
          throw new ApiError(0, i18n.t("notifications.sessionError"));
        else
          throw new ApiError(
            error.response.data.code,
            error.response.data.message
          );
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  async updateStatus(userId) {
    try {
      const response = await ApiService.put(
        ApiService.baseAddress + "currentPlanUser/UpdateStatus",
        userId
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status == 401)
          throw new ApiError(0, i18n.t("notifications.sessionError"));
        else
          throw new ApiError(
            error.response.data.code,
            error.response.data.message
          );
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },

  async deleteUser(userId) {
    try {
      const response = await ApiService.delete(
        ApiService.baseAddress + "currentPlanUser/" + userId
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status == 401)
          throw new ApiError(0, i18n.t("notifications.sessionError"));
        else
          throw new ApiError(
            error.response.data.code,
            error.response.data.message
          );
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },
};

export { CurrentPlanUserService };
