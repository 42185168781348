import { CurrentPlanService } from "@/services/currentPlan.service";
import StorageService from "@/services/storage.service";

export const namespaced = true;

export const state = {
  currentPlan: null,
  currentUser: StorageService.getCurrentUser(),
};

export const getters = {
  currentUser: (state) => {
    if (state.currentPlan) {
      if (state.currentPlan.currentPlanUsers) {
        if (state.currentUser) {
          if (state.currentPlan.currentPlanUsers.filter((user) => user.username == state.currentUser).length > 0) {
            return state.currentPlan.currentPlanUsers.filter((user) => user.username == state.currentUser)[0];
          } else {
            StorageService.saveCurrentUser(state.currentPlan.currentPlanUsers[0].username);
            return state.currentPlan.currentPlanUsers[0];
          }
        } else {
          StorageService.saveCurrentUser(state.currentPlan.currentPlanUsers[0].username);
          return state.currentPlan.currentPlanUsers[0];
        }
      } else return {};
    } else return null;
  },
};

export const mutations = {
  setCurrentPlan(state, currentPlan) {
    state.currentPlan = currentPlan;
  },
  setCurrentUser(state, currentUser) {
    state.currentUser = currentUser;
    StorageService.saveCurrentUser(currentUser);
  },
};

export const actions = {
  async getCurrentPlan({ commit, dispatch }) {
    try {
      const response = await CurrentPlanService.getCurrentPlan();
      commit("setCurrentPlan", response.data.currentPlan);
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async setCurrentUser({ commit }, currentUser) {
    commit("setCurrentUser", currentUser);
  },

  async setCurrentPlan({ commit }, currentPlan) {
    commit("setCurrentPlan", currentPlan);
  },
};
