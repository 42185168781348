<template>
  <side-bar>
    <!-- Navigation Links -->
    <template slot="links">
      <sidebar-item
        :link="{
          name: 'Dashboard',
          icon: 'ni ni-app text-primary',
          path: '/dashboard',
        }"
      >
      </sidebar-item>
      <sidebar-item
        :link="{
          name: 'Subscription',
          icon: 'ni ni-credit-card text-success',
          path: `/${$t('routerPaths.subscription')}`,
        }"
      >
      </sidebar-item>
    </template>

    <!-- Sublinks -->
    <template slot="links-after">
      <!-- Placeholder Links -->
      <hr class="my-3" style="width: 100%" />
      <!-- User Menu -->
      <dashboard-user-menu :plans="plans" />
    </template>
  </side-bar>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardUserMenu from "../Layouts/DashboardUserMenu.vue";

export default {
  components: {
    DashboardUserMenu,
  },
  props: ["plans"],
  mounted() {
    this.initScrollbar();
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
  },
};
</script>

<style></style>
