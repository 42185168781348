// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import "@/polyfills";
// Notifications plugin. Used on Notifications page
import Notifications from "@/components/NotificationPlugin";
// Validation plugin used to validate forms
import { configure } from "vee-validate";
// A plugin file where you could register global components used across the app
import GlobalComponents from "./globalComponents";
// A plugin file where you could register global directives
import GlobalDirectives from "./globalDirectives";
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from "@/components/SidebarPlugin";

// element ui language configuration
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
locale.use(lang);

// vue-bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// asset imports
import "@/assets/sass/argon.scss";
import "@/assets/css/nucleo/css/nucleo.css";
import { extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import tr from "vee-validate/dist/locale/tr.json";
import { localize } from "vee-validate";

localize({
  en,
  tr,
});

localize(process.env.VUE_APP_I18N_LOCALE);

localize({
  tr: {
    names: {
      firstName: "Ad",
      lastName: "Soyad",
      privacyPolicy: "Gizlilik Politikası",
      emailAddress: "E-Posta Adresi",
      password: "Parola",
      passwordConfirm: "Parola Onayı",
      currentPassword: "Mevcut Parola",
      newPassword: "Yeni Parola",
      newPasswordConfirm: "Yeni Parola Onayı",
      username: "Kullanıcı Adı",
      delayTime: "Gecikme Süresi",
    },
    fields: {
      password: {
        min: (_, { length }) => `Parolanız en az ${length} karakterden oluşmalıdır`,
      },
      newPassword: {
        min: (_, { length }) => `Parolanız en az ${length} karakterden oluşmalıdır`,
      },
      newPasswordConfirm: {
        confirmed: "Parola doğrulaması hatalı",
      },
      confirmationCEM: {
        required: "Ticari İletileri almayı kabul etmeniz gerekmektedir",
      },
      confirmationEM: {
        required: "Elektronik iletileri almayı kabul etmeniz gerekmektedir",
      },
    },
  },
  en: {
    names: {
      firstName: "First Name",
      lastName: "Last Name",
      privacyPolicy: "Privacy Policy",
      emailAddress: "Email Address",
      password: "Password",
      passwordConfirm: "Password Confirmation",
      currentPassword: "Current Password",
      newPassword: "New Password",
      newPasswordConfirm: "New Password Confirmation",
      username: "Username",
      delayTime: "Delay Time",
    },
    fields: {
      password: {
        min: (_, { length }) => `Your password must have at least ${length} characters`,
      },
      newPassword: {
        min: (_, { length }) => `Your password must have at least ${length} characters`,
      },
      newPasswordConfirm: {
        confirmed: "Password confirmation is not valid",
      },
      privacyCookiePolicy: {
        required: "You have to agree with the Privacy and Cookie Policy",
      },
    },
  },
});

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
  });
});

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notifications);
    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin);
    configure({
      classes: {
        valid: "is-valid",
        invalid: "is-invalid",
        dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
      },
    });
  },
};
