import { SubscriptionService } from "@/services/subscription.service";

export const namespaced = true;

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
  async createCustomPrice({ dispatch }, customPlan) {
    try {
      const response = await SubscriptionService.createCustomPrice(customPlan);
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async checkout({ dispatch }, payment) {
    try {
      const response = await SubscriptionService.checkout(payment);
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async manage({ dispatch }) {
    try {
      const response = await SubscriptionService.manage();
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async upgrade({ dispatch }, priceId) {
    try {
      const response = await SubscriptionService.upgrade(priceId);
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },

  async delete({ dispatch }) {
    try {
      const response = await SubscriptionService.delete();
      return response;
    } catch (error) {
      dispatch(
        "notification/addNotification",
        {
          type: "danger",
          message: error.errorMessage,
          timeout: 3000,
        },
        { root: true }
      );
    }
  },
};
