import * as signalR from "@microsoft/signalr";
import { ApiService } from "../../services/api.service";
import StorageService from "../../services/storage.service";

const client = new signalR.HubConnectionBuilder()
  .configureLogging(signalR.LogLevel.None)
  .withUrl(ApiService.baseSocketAddress + "ws", {
    accessTokenFactory: () => StorageService.getToken(),
  })
  .build();

export default function createWebSocketPlugin() {
  return (store) => {
    // Start connection if user already signed in
    if (StorageService.getToken() && StorageService.getUser()) {
      client.start();
    }

    store.subscribe((mutation) => {
      // Start connection on sign in
      if (mutation.type == "auth/signInSuccess") {
        client.start();
      }

      // Stop connection on sign out
      if (mutation.type == "auth/signOutSuccess") client.stop();

      // Invoke Socket when User Added
      if (mutation.type == "currentPlanUser/userAdded") client.invoke("UpdateDashboard");

      // Invoke Socket when User Status Updated
      if (mutation.type == "currentPlanUser/userStatusUpdated") client.invoke("UpdateDashboard");

      // Invoke Socket when User Updated
      if (mutation.type == "currentPlanUser/userUpdated") client.invoke("UpdateDashboard");

      // Invoke Socket when User Deleted
      if (mutation.type == "currentPlanUser/userDeleted") client.invoke("UpdateDashboard");
    });

    client.on("dashboardUpdated", (data) => {
      store.dispatch("currentPlan/setCurrentPlan", data.data.currentPlan);
    });
  };
}
