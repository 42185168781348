import { ApiService, ApiError } from "./api.service";
import i18n from "@/i18n";

const PlanService = {
  async getPlans() {
    try {
      const response = await ApiService.get(ApiService.baseAddress + "plan");
      return response.data;
    } catch (error) {
      if (error.response) {
        throw new ApiError(
          error.response.data.code,
          error.response.data.message
        );
      } else if (error.request) {
        throw new ApiError(0, i18n.t("notifications.networkError"));
      }
    }
  },
};

export { PlanService };
