<template>
  <base-dropdown
    ref="dropdown"
    class="pb-2"
    direction="down"
    tag="li"
    title-tag="a"
    title-classes="nav-link"
    :menuClasses="'ml--2'"
  >
    <!-- User Menu Title -->
    <a href="#" @click.prevent class="nav-link" slot="title-container" style="padding: 0px 0px !important">
      <b-media no-body class="align-items-center user-menu">
        <b-avatar size="md" variant="primary">
          <i class="fas fa-user text-default"></i>
        </b-avatar>
        <b-media-body class="pl-2">
          <div v-if="!$sidebar.isMinimized || $sidebar.hovered" class="d-flex flex-row">
            <div class="d-flex flex-column">
              <span class="text-sm font-weight-bold" style="white-space: nowrap">
                {{ currentUser }}
              </span>
              <span class="text-xs text-muted"> {{ userPlan }} </span>
            </div>
            <div class="pl-1">
              <i class="ni ni-bold-down mb-0 ml-1"></i>
            </div>
          </div>
        </b-media-body>
      </b-media>
    </a>

    <!-- User Menu Content -->
    <template>
      <b-dropdown-header class="noti-title">
        <h6 class="text-overflow m-0 ml-1">{{ $t("sidebar.welcome") }}</h6>
      </b-dropdown-header>
      <b-dropdown-item @click="toMyProfile">
        <span>
          <i class="fas fa-user ml-1 mr-2"></i>
          <span class="pl-1">{{ $t("sidebar.profile") }}</span>
        </span>
      </b-dropdown-item>
      <div class="dropdown-divider"></div>
      <b-dropdown-item @click="signOut">
        <span>
          <i class="fas fa-sign-out-alt ml-1 mr-2"></i>
          <span class="pl-1">{{ $t("sidebar.signOut") }}</span>
        </span>
      </b-dropdown-item>
    </template>
  </base-dropdown>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["plans"],
  data: () => ({
    currentUser: "",
  }),
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("user", ["hasPlan"]),
    ...mapState("currentPlan", ["currentPlan"]),
    userPlan() {
      if (this.plans == null || this.hasPlan == null) {
        return "-";
      } else {
        if (this.hasPlan) {
          if (this.currentPlan != null)
            return `${this.plans.filter((plan) => this.currentPlan.planId == plan.id)[0].name} Plan`;
          else return "-";
        } else return "No Subscription";
      }
    },
  },
  created() {
    this.currentUser = `${this.user.firstName} ${this.user.lastName}`;
  },
  watch: {
    $sidebar: {
      deep: true,
      handler() {
        if (this.$sidebar.isMinimized && !this.$sidebar.hovered) this.$refs.dropdown.closeDropDown();
      },
    },
  },
  methods: {
    toMyProfile() {
      this.$router.push({ name: "myProfile" }).catch(() => {});
      this.$sidebar.displaySidebar(false);
    },
    signOut() {
      this.$store.dispatch("auth/signOut");
    },
  },
};
</script>

<style>
.user-menu {
  margin-left: -13px;
}
</style>
